import request from "@/utils/request";

export function getCheckAuth(){
  return request({
    url: '/admin_auth/check_auth',
    method: 'get',
  })
}

export function getAuthList(data){
  return request({
    url: '/admin_auth/list',
    method: 'post',
    data: data,
  })
}

export function getAuthInfo(id){
  return request({
    url: '/admin_auth/info/' + id,
    method: 'get',
  })
}

export function addAuth(data){
  return request({
    url: '/admin_auth/add',
    method: 'post',
    data: data,
  })
}

export function editAuth(id, data){
  return request({
    url: '/admin_auth/edit/' + id,
    method: 'put',
    data: data,
  })
}

export function delAuth(data){
  return request({
    url: '/admin_auth/del',
    method: 'post',
    data: data,
  })
}
