<template>
    <el-dialog :visible.sync="formDialog" title="添加" :close-on-click-modal="false" @close="closeForm">
        <el-form ref="dataForm" :model="dataForm" :rules="ruleForm" label-width="100px" class="data-form">
            <el-form-item label="权限名" prop="name">
                <el-input v-model="dataForm.name" placeholder="权限名"></el-input>
            </el-form-item>
            <el-form-item label="权限url" prop="url">
                <el-input v-model="dataForm.url" placeholder="权限url"></el-input>
            </el-form-item>
            <el-form-item label="路径" prop="component">
                <el-input v-model="dataForm.component" placeholder="路径"></el-input>
            </el-form-item>
            <el-form-item label="上级" prop="pid">
                <el-select v-model="dataForm.pid" placeholder="选择上级">
                    <el-option :key="0" :label="'顶级url'" :value="0"></el-option>
                    <el-option v-for="item in checkAuth" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="图标" prop="icon">
                <el-input v-model="dataForm.icon" placeholder="图标"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input v-model="dataForm.sort" placeholder="排序"></el-input>
            </el-form-item>
            <el-form-item label="是否显示" prop="is_show">
                <el-radio-group v-model="dataForm.is_show">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="0">隐藏</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否菜单" prop="is_menu">
                <el-radio-group v-model="dataForm.is_menu">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="启用状态" prop="status">
                <el-radio-group v-model="dataForm.status">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="0">禁用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
                <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { getCheckAuth, addAuth } from '@/utils/servers/adminAuth.js';
export default {
    props: ['addFormDialog'],
    data() {
        return {
            formDialog: this.addFormDialog,
            dataForm: {
                name: '',
                url: '',
                component: '',
                pid: 0,
                icon: '',
                sort: 0,
                is_show: 1,
                is_menu: 0,
                status: 1
            },
            ruleForm: {
                name: [{ required: true, trigger: 'blur' }],
                url: [{ required: true, trigger: 'blur' }],
                component: [{ required: true, trigger: 'blur' }],
                pid: [{ required: true, trigger: 'blur' }],
                // icon: [{ required: true, trigger: 'blur' }],
                sort: [{ required: true, trigger: 'blur' }],
                is_show: [{ required: true, trigger: 'blur' }],
                is_menu: [{ required: true, trigger: 'blur' }],
                status: [{ required: true, trigger: 'blur' }]
            },
            checkAuth: []
        };
    },
    created() {
        this.getCheckAuth();
    },
    methods: {
        getCheckAuth() {
            getCheckAuth().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkAuth = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        submitForm() {
            addAuth(this.dataForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    this.closeForm();
                    this.$emit('getAuthList');
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        resetForm() {
            this.$refs.dataForm.resetFields();
        },
        closeForm() {
            this.$emit('closeForm', 'add');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
}
</style>
